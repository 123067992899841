body {
  margin: 0;
  font-family: 'Inter Variable', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

[data-amplify-theme="default-theme"] {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: rgb(252, 255, 252);
}

.pagination-footer {
  position: absolute;
  bottom: 0;
}